/* eslint-disable react-hooks/exhaustive-deps */
import { styled, Button, Grow, Box, Grid, Card, CardContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography, Table, TableBody, TableContainer, TableRow, TableCell } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTranslation, LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Infrastructure/Redux/hooks";
import { MetaDataDto } from "../../Features/Home/Models/MetaDataDto";
import { ProcessDataSetDto } from "../../Features/Home/Models/ProcessDataSetDto";
import { Helmet } from "react-helmet";
import { OnlineHelpIconButton } from "../../Infrastructure/OnlineHelp/Components/OnlineHelpIconButton";
import { InformationOnlineHelp } from "../../Infrastructure/OnlineHelp/Information/InformationOnlineHelp";
import { analyze_ShowOnlineHelp } from "../../Infrastructure/Analytics/Redux/AnalyticsSlice";
import { OnlineHelpItems } from "../../Infrastructure/Analytics/Model/Categories/OnlineHelpEnums";
import { MetadataCompareRequest } from "../MetaDataCompare/Api/MetaDataCompareApi";
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import { metaDataCompareSourceChanged, metaDataCompareAsync } from "../MetaDataCompare/Redux/MetaDataCompareSlice";
import { MetaDataCompareResult } from "../Home/Models/MetaDataCompareResult";
import { HeaderInformationItemDto } from "../Home/Models/HeaderInformationItemDto";
import { MetaDataDataType } from "../Home/Models/MetaDataDataType";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { FileDownload, KeyboardArrowRight, KeyboardArrowUp, ShowChart } from "@mui/icons-material";
import { InformationType } from "../Settings/Redux/SettingsSlice";
import { LittleButton } from "../../Infrastructure/OnlineHelp/Information/LittleButton";
import { downloadFinalValuesFile } from "./DownloadFinalValuesApi";
import { SuperVisualizationModeDto } from "../Home/Models/SuperVisualizationModeDto";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { SingleVisualizationModeDto } from "../Home/Models/SingleVisualizationModeDto";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.paper,
    },
}));

export const Information = () => {
    const dispatch = useAppDispatch();
    const allInformations = useAppSelector(store => store.chartResultData.series[0]?.MetaData);
    const curveData = useAppSelector(store => store.chartResultData.series[0]);
    const [selectedCompareSource, setSelectedCompareSource] = useState<MetaDataDto | null>();
    const [selectedCompareTarget, setSelectedCompareTarget] = useState<MetaDataDto | null>();
    const [shouldCompare, setShouldCompare] = useState(false);
    const [isOnlineHelpEnabled, setIsOnlineHelpEnabled] = useState(false);
    const [shouldCompareBeforeOnlineHelp, setShouldCompareBeforeOnlineHelp] = useState(false);
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
    const metaDataCompareResult = useAppSelector(store => store.metaDataCompare);
    const isInformationModal = useAppSelector(store => store.settings.informationType === InformationType.Modal);
    const singleVisualizationModeIsTime = useAppSelector(store => store.settings.chartRequestSettings.SingleVisualization!.Mode === SingleVisualizationModeDto.Time)
    const chartRequest = useAppSelector(store => store.chartRequestData.series);
    const isNotSuperVisualisation = useAppSelector(store => store.settings.chartRequestSettings.SuperVisualization?.Mode === SuperVisualizationModeDto.None);

    useEffect(() => {
        if (selectedCompareSource == null) {
            setSelectedCompareSource(allInformations && allInformations.length > 0 ? allInformations[0] : null)
        }
    }, []);

    useEffect(() => {
        if (selectedCompareSource !== undefined) {
            setShouldCompare(false);
            dispatch(metaDataCompareSourceChanged(selectedCompareSource));
        }
    }, [selectedCompareSource])

    useEffect(() => {
        if (selectedCompareSource !== undefined && selectedCompareTarget !== undefined) {

            dispatch(metaDataCompareAsync({
                Source: selectedCompareSource,
                Targets: [selectedCompareTarget]
            } as MetadataCompareRequest)
            );
        }

    }, [selectedCompareTarget])

    const setShouldCompareToTrue = () => {
        setShouldCompareBeforeOnlineHelp(shouldCompare)
        setShouldCompare(true);
    }

    const closeOnlineHelp = () => {
        setShouldCompare(shouldCompareBeforeOnlineHelp)
        setIsOnlineHelpEnabled(false);

    };
    const openOnlineHelp = () => {
        setIsOnlineHelpEnabled(true)
        dispatch(analyze_ShowOnlineHelp({ source: OnlineHelpItems.InformationsOnlineHelp }))
    };
    

    const downloadFinalValues = () => {
        if (chartRequest) {
            downloadFinalValuesFile(chartRequest)
        }
    };

    const downloadCurveData = () => {
        const workbook = XLSX.utils.book_new();
    
        curveData.ChartSeries.forEach(series => {
            const scaleFactor = parseFloat(series.ScaleFactor);
            const unitAbbreviation = series.UnitAbbreviation
            const titleWithUnit = unitAbbreviation ? `${getTranslation(series.Title)} (${unitAbbreviation})` : getTranslation(series.Title)

            const data = series.XValues.map((xValue, index) => {
                const yValue = (series.YValues[index] / scaleFactor); 
                return [xValue, yValue];
            });
            console.log(data);
    
            const sheet = XLSX.utils.aoa_to_sheet([[getTranslation("TimeCsvName"), titleWithUnit], ...data]);
            XLSX.utils.book_append_sheet(workbook, sheet, getTranslation(series.Title));
        });
    
        const result = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([result], { type: 'application/octet-stream' }), 'curvedata.xlsx');
    };

    return (

        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>
                    <Helmet>
                        <title>{getTranslatedText("InformationsGraphViewerTitle")}</title>
                    </Helmet>
                    <Grow in={true} appear={true}>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12}>
                                <Card elevation={12} sx={{mt: isInformationModal ? 1 : 0}} >
                                    <CardContent>
                                        <InformationOnlineHelp
                                            closeOnlineHelp={closeOnlineHelp}
                                            showOnlineHelp={isOnlineHelpEnabled}
                                            allInformations={allInformations}
                                            shouldCompare={shouldCompare}
                                            setShouldCompareToTrue={setShouldCompareToTrue} />
                                        <Grid container direction={"row"}>
                                            <Grid item xs={11} >
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    {getTranslatedText("Information")}
                                                </Typography>
                                            </Grid>

                                            {allInformations && 
                                            <Grid item container xs={1} direction={"row"} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                { allInformations.length === 1 && allInformations[0].Title.endsWith(".rff") && 
                                                <Grid item style={{marginRight: 10}}>
                                                    <LittleButton
                                                        title={"DownloadFinalValues"}
                                                        onClickFunction={downloadFinalValues} 
                                                        icon={<FileDownload fontSize={"small"}/>}/>
                                                </Grid>
                                                }
                                                { allInformations.length === 1 && isNotSuperVisualisation &&
                                                <Grid item style={{marginRight: 10}}>
                                                    <LittleButton
                                                    title={!singleVisualizationModeIsTime ? "DownloadCurveDataDisabled" : "DownloadCurveData"}
                                                    onClickFunction={downloadCurveData} 
                                                    disabled={!singleVisualizationModeIsTime}
                                                    icon={<ShowChart fontSize={"small"}/>}/>
                                                </Grid>
                                                }
                                                <Grid>
                                                    <OnlineHelpIconButton
                                                        isInSettings={false}
                                                        title={"OnlineHelp"}
                                                        isOnlineHelpEnabled={false}
                                                        onClickFunction={openOnlineHelp} />
                                                </Grid>
                                            </Grid>}
                                        </Grid>

                                        <Grid container direction={'row'} sx={{ mt: 2 }} spacing={2} className="onlinehelp-information-switchCompareCurvesOfSameType">

                                            {selectedCompareSource && allInformations ? <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel color="success" data-testid="select-compare-source">{getTranslatedText("InformationForFile")} </InputLabel>
                                                    <Select
                                                        className="onlinehelp-information-selectCurveData1"
                                                        color="success"
                                                        labelId="select-label-theme"
                                                        id="select-theme"
                                                        value={selectedCompareSource != null ? selectedCompareSource : ""}
                                                        label={getTranslatedText("InformationForFile")}
                                                        onChange={(event: SelectChangeEvent<MetaDataDto>) => {
                                                            setSelectedCompareSource(event.target.value as MetaDataDto);
                                                            setSelectedCompareTarget(undefined);
                                                        }}
                                                    >
                                                        {allInformations.map(((info, key) => {
                                                            return (<MenuItem key={key} value={info as any}>{info.Title} </MenuItem>)
                                                        }))}
                                                    </Select>
                                                </FormControl>
                                            </Grid> :
                                                <Grid item xs={12}>
                                                    <Typography color={'text.secondary'}>{getTranslatedText("NoDataAvailable")}</Typography>
                                                </Grid>}

                                            {selectedCompareSource && allInformations && allInformations.length > 1 && !shouldCompare && <Grid item xs={6}>
                                                <Button variant="outlined" sx={{ height: '56px' }} fullWidth color={'success'} onClick={() => {
                                                    setShouldCompare(oldValue => !Boolean(oldValue));
                                                    setSelectedCompareTarget(undefined);
                                                }}>
                                                    {getTranslatedText("CompareFiles")}
                                                </Button>
                                            </Grid>}

                                            {selectedCompareSource && allInformations && shouldCompare && <Grid item sx={{ mt: 0 }} xs={6}>
                                                <FormControl fullWidth sx={{ mt: 0 }}>
                                                    <InputLabel color="success" data-testid="select-compare-target">{getTranslatedText("InformationForCompareFile")} </InputLabel>
                                                    <Select
                                                        className="onlinehelp-information-selectCurveData2"
                                                        color="success"
                                                        labelId="select-label-theme"
                                                        id="select-theme"
                                                        value={selectedCompareTarget != null ? selectedCompareTarget : ""}
                                                        label={getTranslatedText("InformationForCompareFile")}
                                                        onChange={(event: SelectChangeEvent<MetaDataDto>) => {
                                                            setSelectedCompareTarget(event.target.value as MetaDataDto);
                                                        }}
                                                    >
                                                        {allInformations.filter(x => x.Title.split('.').pop() === selectedCompareSource?.Title.split('.').pop() && x !== selectedCompareSource).map(((info, key) => {
                                                            return (<MenuItem key={key} value={info as any}>{info.Title} </MenuItem>)
                                                        }))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>}
                                        </Grid>

                                        {selectedCompareSource &&

                                            <Grid container direction={'row'} spacing={2}>
                                                {(shouldCompare && selectedCompareTarget !== undefined) ?
                                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                                        {metaDataCompareResult.content?.GeneralDataList.length !== 0 &&
                                                            <GeneralDataTable isDarkMode={isDarkMode} content={metaDataCompareResult.content} titles={metaDataCompareResult.titles} />}
                                                        {metaDataCompareResult.content?.DetailsDataList.length !== 0 &&
                                                            <DetailsDataTable isDarkMode={isDarkMode} content={metaDataCompareResult.content} titles={metaDataCompareResult.titles} canCompareDetails={metaDataCompareResult.canCompareDetails} />}
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                                        {selectedCompareSource?.GeneralDataList.length !== 0 &&
                                                            <GeneralDataTable isDarkMode={isDarkMode} content={selectedCompareSource} titles={[(selectedCompareSource).Title]} />}
                                                        {selectedCompareSource?.DetailsDataList.length !== 0 &&
                                                            <DetailsDataTable isDarkMode={isDarkMode} content={selectedCompareSource} titles={[(selectedCompareSource).Title]} canCompareDetails={metaDataCompareResult.canCompareDetails} />}
                                                    </Grid>

                                                }

                                            </Grid>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grow>
                </React.Fragment>
            }
        </LanguageConsumer>
    );
}


export const GeneralDataTable = ({ content, titles, isDarkMode }: { content: MetaDataDto | null, titles: string[] | null, isDarkMode: boolean }) => {
    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid className="onlinehelp-information-generalInformation" item sx={{ mb: 2 }} xs={12}>
                    <TableContainer component={'div'} sx={{ borderRadius: "10px", border: isDarkMode ? "1px solid #444" : "1px solid #ededed" }}>
                        <Table aria-label="generalDataTable">
                            <TableHead sx={{ background: isDarkMode ? '#444' : '#ededed' }}>
                                <TableRow>
                                    <TableCell key={"general-table-header-col-1"} width="66" />
                                    <TableCell key={"general-table-header-col-2"} width="415">{getTranslatedText("GeneralInformation")}</TableCell>
                                    {titles != null &&
                                        titles.map((title, index) => (
                                            <TableCell key={index} align="center">{title}</TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(content != null) && (content.GeneralDataList != null) &&
                                    content.GeneralDataList.map((data, index) => (
                                        <GeneralTableBody key={index} isDarkMode={isDarkMode} data={data} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            }
        </LanguageConsumer>
    );
}

const GeneralTableBody = ({ data, isDarkMode }: { data: HeaderInformationItemDto, isDarkMode: boolean }) => {
    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>
                    {data.Compares![0].Value != null &&
                        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>                    
                            <TableCell />
                            <TableCell component="th" scope="row" >
                                {getTranslatedText(data.DescriptionLanguageKey)}
                            </TableCell>

                            {data.Compares?.map((comparedData, key) =>
                                <TableCell key={key} align="center">
                                    <Box minHeight={36.0156} component={'div'} sx={{ borderRadius: 3, px: 3, py: 1, color: getTextColor(comparedData.CompareResult, isDarkMode), background: getBackgroundColor(comparedData.CompareResult) }}>{data.DataType === MetaDataDataType.Bool ?
                                        getTranslatedText(getBoolIdentifier(comparedData.Value))
                                        :
                                        comparedData.Value === "{}" ? "" : typeof (comparedData.Value) === "string" ? getTranslatedText(getViewedDataValue(data.DescriptionLanguageKey, comparedData.Value)) : comparedData.Value}</Box>
                                </TableCell>
                            )}

                        </StyledTableRow>
                    }
                </React.Fragment>
            }
        </LanguageConsumer>
    );
}

export const DetailsDataTable = ({ content, titles, isDarkMode, canCompareDetails }: { content: MetaDataDto | null, titles: string[] | null, isDarkMode: boolean, canCompareDetails: boolean }) => {
    const isDeveloper = useAppSelector(store => store.developer.isDeveloper)

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Grid className="onlinehelp-information-connectionElementInformations" item xs={12} sx={{ mt: 5 }}>
                    {canCompareDetails ?
                        <TableContainer component={'div'} sx={{ borderRadius: "10px", border: isDarkMode ? "1px solid #444" : "1px solid #ededed" }}>
                            <Table aria-label="detailsDataTable">
                                <TableHead sx={{ background: isDarkMode ? '#444' : '#ededed' }}>
                                    <TableRow>
                                        <TableCell width="66" />
                                        <TableCell width="415">{getTranslatedText("FastenerAndScrewParameters")}</TableCell>
                                        {titles != null &&
                                            titles.map((title, key) => (
                                                <TableCell key={key} align="center">{title}</TableCell>
                                            ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(content != null) && (content.DetailsDataList != null) &&
                                        content.DetailsDataList.map((data, key) => (
                                            (!data.IsInternal || isDeveloper) &&
                                            <DetailsTableBody isDarkMode={isDarkMode} key={key} data={data} IsOpen={true} propertyLevel={0} />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        <Grid color={"#d1d1d1"} item xs={12} sx={{ mt: 5, textAlign: "center" }}>
                            <Box component={'div'} sx={{ border: isDarkMode ? '1px solid #444' : '1px solid #ededed', borderRadius: 3, p: 3 }}>
                                <SyncProblemIcon fontSize="large" />
                                <Typography fontWeight={'200'} fontSize={18}>{getTranslatedText("CompareDetailsImpossible")}</Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            }
        </LanguageConsumer>

    );
}

const DetailsTableBody = ({ data, IsOpen, propertyLevel, isDarkMode }: { data: ProcessDataSetDto, IsOpen: boolean, propertyLevel: number, isDarkMode: boolean }) => {
    const isDeveloper = useAppSelector(store => store.developer.isDeveloper)
    const [expandChildren, setExpandChildren] = React.useState(false);

    const getTranslatedTextIdentifier = (getTranslatedText: (key: string) => string, text: string) => {
        var reg = /@TEXT[_a-zA-Z0-9-]*/g;
        var matches = text.match(reg);

        if (matches) {
            var returnText = text;
            for (let match of matches) {
                returnText = returnText.replace(match, getTranslatedText(match.replace("@", "")))
            }
            return returnText;
        } else {
            return "-"
        }
    }

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <React.Fragment>
                    {IsOpen && (data.Compares![0].Value != null || data.Children != null) && <React.Fragment>
                        <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                            {data.Children != null ?
                                <TableCell>
                                    <IconButton
                                        sx={{ pl: propertyLevel * 3 }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setExpandChildren(!expandChildren)}
                                    >
                                        {expandChildren ? <KeyboardArrowRight /> : <KeyboardArrowUp />}
                                    </IconButton>
                                </TableCell>
                                :
                                <TableCell />}
                            <TableCell sx={{ pl: propertyLevel * 3 + 2 }} component="th" scope="row" >
                                {getTranslatedTextIdentifier(getTranslatedText, data.DescriptionLanguageKey)}
                            </TableCell>

                            {data.Compares?.map((comparedData, key) =>
                                <TableCell key={key} align="center">
                                    <Box minHeight={36.0156} component={'div'} sx={{ borderRadius: 3, px: 3, py: 1, color: getTextColor(comparedData.CompareResult, isDarkMode), background: getBackgroundColor(comparedData.CompareResult) }}>{
                                        data.DataType === MetaDataDataType.Bool ?
                                            getTranslatedText(getBoolIdentifier(comparedData.Value))
                                            :
                                            comparedData.Value === "{}" ? "" : typeof (comparedData.Value) === "string" ? getTranslatedText(getViewedDataValue(data.DescriptionLanguageKey, getTranslatedText(comparedData.Value.toString()))) : comparedData.Value}</Box>
                                </TableCell>
                            )}

                        </StyledTableRow>

                        {(data.Children != null) &&
                            data.Children.map((data, key) => (
                                (!data.IsInternal || isDeveloper) && <DetailsTableBody isDarkMode={isDarkMode} key={key} data={data} IsOpen={expandChildren} propertyLevel={propertyLevel + 1} />
                            ))}

                    </React.Fragment>
                    }
                </React.Fragment>
            }
        </LanguageConsumer>
    );
}

const getBackgroundColor = (compareResult: MetaDataCompareResult) => {
    if (compareResult === MetaDataCompareResult.Equal) {
        return "#32992c";
    }
    else if (compareResult === MetaDataCompareResult.Missing) {
        return "#3ea3f0";
    }
    else if (compareResult === MetaDataCompareResult.Different) {
        return "#f03e3e";
    }
}

const getTextColor = (compareResult: MetaDataCompareResult, isDarkMode: boolean) => {
    if (compareResult === MetaDataCompareResult.Equal) {
        return "#fff";
    }
    else if (compareResult === MetaDataCompareResult.Missing) {
        return "#fff";
    }
    else if (compareResult === MetaDataCompareResult.Different) {
        return "#fff";
    } else {
        return isDarkMode ? '#fff' : "#444"
    }
}

const getBoolIdentifier = (value: string | null) => {
    return value === "True" ? "InformationBooleanValueTrue" : "InformationBooleanValueFalse";
}

const getViewedDataValue = (DescriptionLanguageKey: string, value: string) => {
    const HeaderInfoProcessType: { [key: string]: string } = {
        '0': 'TEXT_UNKOWNSTEP',
        '1': 'TEXT_Flowform',
        '2': 'TEXT_AdaptiveTightening',
        '3': 'TEXT_ClampForceControl'
    };

    const Ermittlungsverfahren: { [key: string]: string } = {
        '0': 'TEXT_FrictionValueAverageValue',
        '1': 'TEXT_FrictionValueMaximumValue'
    };

    const CfcAbschaltverhalten: { [key: string]: string } = {
        '0': 'TEXT_AbsolutesDrehmoment',
        '1': 'TEXT_Differenmoment',
        '2': 'TEXT_Differenzwinkel'
    };

    if (DescriptionLanguageKey === 'HeaderInfo_ProcessType') {
        return HeaderInfoProcessType[value] || value;
    }
    else if (DescriptionLanguageKey === '4.84 @TEXT_Ermittlungsverfahren') {
        return Ermittlungsverfahren[value] || value;
    }
    else if (DescriptionLanguageKey === '5.94 @TEXT_CfcAbschaltverhalten') {
        return CfcAbschaltverhalten[value] || value;
    }

    return value;
};
  