import { LanguageConsumer } from "../../Internationalisation/TranslationService";
import { IconButton, Tooltip, Grid } from "@mui/material";
import { useAppSelector } from "../../Redux/hooks";

export interface LittleButtonProps {
    title : string,
    onClickFunction: () => void, 
    icon: any
    disabled? : boolean
  }

export const LittleButton = (props : LittleButtonProps) => {
    const isDarkMode = useAppSelector(store => store.settings.appTheme === 'dark');
  const size = 'small';

  const { title, onClickFunction, icon, disabled = false } = props; 
  return (
    <LanguageConsumer>

      {({ getTranslatedText }) =>
       
          <Tooltip title={getTranslatedText(title)} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Grid>
              <IconButton
                size={size}
                disabled={disabled}
                color="success"
                onClick={onClickFunction}
                sx={{
                  color: isDarkMode ? '#c2c0c0' : '#757474',
                  background: isDarkMode ? '#424242' : '#DFE7FD'
                }}
              >
                {icon}
              </IconButton>
              </Grid>
          </Tooltip>
       
      }

    </LanguageConsumer>
  );
}